import React, { useState } from 'react';
import Cookies from 'js-cookie';
import '../styles/CookieBannerStyle.css'; // Import der separaten CSS-Datei

const CookieBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState(!Cookies.get('cookieConsent'));

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    setIsVisible(false);
  };

  return isVisible ? (
    <div className="cookie-banner">
      <p className="cookie-banner__text">
        Wir verwenden Cookies, um Ihre Erfahrung zu verbessern. 
        Lesen Sie mehr in unserer <a href="https://www.phantiwood.de/content/6-datenschutz" className="cookie-banner__link">Datenschutzerklärung</a>.
      </p>
      <div className="cookie-banner__buttons">
        <button className="cookie-banner__button cookie-banner__button--accept" onClick={handleAccept}>
          Akzeptieren
        </button>
        <button className="cookie-banner__button cookie-banner__button--decline" onClick={() => setIsVisible(false)}>
          Ablehnen
        </button>
      </div>
    </div>
  ) : null;
};

export default CookieBanner;