import React, { useState } from 'react';
import '../styles/MainPageStyle.css';
import { useNavigate } from 'react-router-dom';

interface Props {
  setMailAdress: React.Dispatch<React.SetStateAction<string>>;
  setDBGenre: React.Dispatch<React.SetStateAction<string>>;
  setSongtext: React.Dispatch<React.SetStateAction<string>>;
}

const MainPage = ({ setMailAdress, setDBGenre, setSongtext }: Props) => {
  const [genre, setGenre] = useState<string>('Rock');
  const [lyrics, setLyrics] = useState<string>('');
  const [mail, setMail] = useState<string>('');
  const [textOption, setTextOption] = useState<string>('Eigener Songtext');
  const [keywords, setKeywords] = useState<string>('');
  const navigate = useNavigate();

  const handleMailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
  };

  const handleGenreChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGenre(e.target.value);
  };

  const handleLyricsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLyrics(e.target.value);
  };

  const handleTextOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTextOption(e.target.value);
  };

  const handleKeywordsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeywords(e.target.value);
  };

  const handleGenerateLyrics = async () => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`, // API-Schlüssel hier korrekt einfügen
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `Erstelle einen Songtext basierend auf den folgenden Stichworten ${keywords} sowie dem Genre ${genre}`,
            },
            {
              role: 'user',
              content: `Erstelle einen Songtext basierend auf den folgenden Stichworten ${keywords} sowie dem Genre ${genre}`,
            },
          ],
        }),
      });

      if (response.status === 429) {
        const content = `Zu viele Anfragen im Moment`;
        setLyrics(content);
        return;
      }

      if (!response.ok) {
        const content = `API Fehler: ${response.status} ${response.statusText}`;
        setLyrics(content);
        return;
      }

      const data = await response.json();
      const generatedContent = data.choices[0].message.content;
      setLyrics(generatedContent);
    } catch (error) {
      console.error('Fehler beim Abrufen der API:', error);
    }
  };

  const handleOrderSong = () => {
    setMailAdress(mail);
    setDBGenre(genre);
    setSongtext(lyrics);
    navigate('/PayPage');
  };

  const isFormComplete =
    mail.trim() !== '' &&
    lyrics.trim() !== '' &&
    genre.trim() !== '' &&
    (textOption === 'Eigener Songtext' || keywords.trim() !== '');

  return (
    <div className="mainPage_app">
      <header className="mainPage_header">
        <p>
          Erlebe KI-generierte Musik! Wähle ein Genre, gib deinen Songtext ein, und lasse uns Magie
          kreieren.
        </p>
      </header>
      <main className="mainPage_main">
        {/* Versand-Hinweis */}
        <div className="mainPage_shipping-info">
          <p>
            Dein Song wird innerhalb von <strong>3–4 Werktagen</strong> an deine E-Mail-Adresse
            versendet.
          </p>
        </div>

        {/* Preisschild */}
        <div className="mainPage_price-tag">
          <p>
            Jetzt nur: <span className="mainPage_discounted-price">119€</span>{' '}
            <span className="mainPage_original-price">249€</span>
          </p>
        </div>

        {/* Audio Player */}
        <div className="mainPage_audio-preview">
          <p>Hörprobe:</p>
          <audio controls>
            <source src="https://songs.phantiwood.de/Server/Audio/H%C3%B6rprobe_1.mp3" type="audio/mpeg" />
            Dein Browser unterstützt den Audio-Player nicht.
          </audio>
        </div>

        <div className="mainPage_form">
          <label htmlFor="mail">Deine Mail-Adresse:</label>
          <input
            id="mail"
            type="email"
            placeholder="name@example.com"
            value={mail}
            onChange={handleMailChange}
          />

          <label htmlFor="genre">Wähle ein Genre:</label>
          <select id="genre" value={genre} onChange={handleGenreChange}>
            <option value="Rock">Rock</option>
            <option value="Pop">Pop</option>
            <option value="Schlager">Schlager</option>
          </select>

          <label htmlFor="textOption">Wähle Songtext-Option:</label>
          <select id="textOption" value={textOption} onChange={handleTextOptionChange}>
            <option value="Eigener Songtext">Eigener Songtext</option>
            <option value="Songtext generieren lassen">Songtext generieren lassen</option>
          </select>

          {textOption === 'Songtext generieren lassen' && (
            <>
              <label htmlFor="keywords">Gib Keywords für den Songtext ein:</label>
              <input
                id="keywords"
                type="text"
                placeholder="z. B. Liebe, Freiheit, Abenteuer"
                value={keywords}
                onChange={handleKeywordsChange}
              />
              <button onClick={handleGenerateLyrics}>Songtext generieren</button>
            </>
          )}

          <label htmlFor="lyrics">Songtext:</label>
          <textarea
            id="lyrics"
            placeholder="Schreibe hier deinen Text..."
            value={lyrics}
            onChange={handleLyricsChange}
          />

          {!isFormComplete && (
            <p className="mainPage_warning">Bitte fülle alle Felder aus, um fortzufahren.</p>
          )}

          <button onClick={handleOrderSong} disabled={!isFormComplete}>
            Song bestellen
          </button>
        </div>
      </main>
    </div>
  );
};

export default MainPage;