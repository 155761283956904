import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';
import './App.css';
import PayPage from './pages/PayPage';
import Footer from './pages/Footer';
import Header from './pages/Header';
import NotFound from './pages/NotFound';
import ThanksOrder from './pages/ThanksOrder';
import CookieBanner from './pages/CookieBanner';


function App() {
  const [mailAdress, setMailAdress] = useState<string>("");
  const [genre, setGenre] = useState<string>("");
  const [songtext, setSongtext] = useState<string>("");
  const [orderID, setOrderID] = useState<string>("");

  document.title = "PhantiSongs";

  return (
    <div className="App">
      <Header/>
      <Router>
        <Routes>
          <Route path="*" element={<NotFound/>} /> 
          <Route path="/" element={<MainPage setMailAdress={setMailAdress} setDBGenre={setGenre} setSongtext={setSongtext}/>} />
          <Route path ="/PayPage" element={<PayPage mailAdress={mailAdress} genre={genre} songtext={songtext} setOrderNo={setOrderID} />} />
          <Route path ="/ThanksOrder" element={<ThanksOrder orderNumber={orderID} />} />
        </Routes>
      </Router>
      <CookieBanner/>
      <Footer/>
    </div>
 
  );
}

export default App;

