import React from 'react';
import '../styles/Footer.css'; // Importiere die CSS-Datei

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h4>Unsere Firma</h4>
          <ul className="footer-list">
            <li><a href="https://www.phantiwood.de/" className="footer-link">Über uns</a></li>
        </ul>
        </div>
        <div className="footer-column">

        
          <h4>Shop Informationen</h4>
          <ul className="footer-list">
            <li><a href="https://www.phantiwood.de/content/2-impressum" className="footer-link">Impressum</a></li>
            <li><a href="https://www.phantiwood.de/content/1-Lieferung" className="footer-link">Versand</a></li>
            <li><a href="https://www.phantiwood.de/content/7-widerruf" className="footer-link">Wiederruf</a></li>
            <li><a href="https://www.phantiwood.de/content/6-datenschutz" className="footer-link">Datenschutz</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Kontakt</h4>
          <p>
            Addresse: Schlossstraße 12, <br />
            37281 Wanfried
          </p>
          <p>Mobil: +49 0152 037 94104 </p>
          <p>Email: <a href="mailto:songs@phantiwood.de">songs@phantiwood.de</a></p>
        </div>
        <div className="footer-column">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
            alt="PayPal Logo"
            className="paypal-logo"
          />
          <div className="card-logos">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg"
              alt="Visa"
              className="card-logo"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png"
              alt="MasterCard"
              className="card-logo"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/f/fa/American_Express_logo_%282018%29.svg"
              alt="American Express"
              className="card-logo"
            />
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        Copyright © 2024 Phantiwood. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;