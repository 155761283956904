import React from 'react';
import '../styles/Header.css'; // Importiere die CSS-Datei

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="header-container">
        <div className="logo">
          <h1>PhantiSongs</h1>
        </div>
      </div>
    </header>
  );
};

export default Header;