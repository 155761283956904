import React from 'react';
import '../styles/ThanksOrderStyle.css';
import { useNavigate } from 'react-router-dom';

interface Props {
  orderNumber: string;
}

const ThanksOrder = ({ orderNumber }: Props) => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className="thanksOrder_app">
      <header className="thanksOrder_header">
        <h1>Vielen Dank für Ihre Bestellung!</h1>
      </header>
      <main className="thanksOrder_main">
        <div className="thanksOrder_content">
          <p>
            Ihre Bestellnummer lautet: <strong>{orderNumber}</strong>
          </p>
          <p>
            Wir senden Ihren individuell generierten Song innerhalb von 3–4
            Werktagen an die angegebene E-Mail-Adresse.
          </p>
          <button className="thanksOrder_button" onClick={handleBackToHome}>
            Zur Startseite
          </button>
        </div>
      </main>
    </div>
  );
};

export default ThanksOrder;